var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"sm12":"","md12":""}},[(!_vm.forbidden)?_c('v-card',{staticStyle:{"height":"85vh"},attrs:{"outlined":""}},[_c('div',[_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"id":"cardTitle"}},[_c('v-card-title',{staticClass:"sb-form-cardTitle"},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-cloud-upload-outline")]),(!_vm.isPage)?_c('div',[_vm._v("模型上傳")]):_vm._e(),_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v(_vm._s(_vm.selectedGName)+" "),(!_vm.uploading)?_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"small":"","icon":""}},on),[_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,false,1000270303)},[_c('v-list',{staticClass:"pa-0",attrs:{"subheader":"","flat":"","dense":""}},[_c('v-subheader',[_vm._v("選擇上傳群組")]),_c('v-divider'),_c('v-card',{staticStyle:{"overflow-y":"scroll"},attrs:{"max-height":"300","min-width":"140"}},[_c('v-list-item-group',{attrs:{"color":"primary","active-class":"groupMenuActive"},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}},[_vm._l((_vm.myGroups),function(item){return [_c('v-list-item',{key:item.id},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)],1)]})],2)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-fab-transition',[_c('v-btn',_vm._g({staticClass:"mb-5 mr-1",attrs:{"color":"primary","fab":"","dark":"","x-small":"","absolute":"","bottom":"","right":"","elevation":1},on:{"click":function($event){return _vm.addGroup()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]}}],null,false,1023865043)},[_c('span',[_vm._v("新增群組")])])],1):_vm._e(),_c('v-spacer'),(_vm.balance !== null && _vm.balance < 10)?_c('v-card',{attrs:{"flat":""}},[_c('QuotaAlert',{attrs:{"balance":_vm.balance}})],1):_vm._e(),(_vm.mode !== 'check')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"actionBtn",attrs:{"color":"primary","fab":_vm.$vuetify.breakpoint.name == 'xs',"small":_vm.$vuetify.breakpoint.name == 'xs',"depressed":"","dark":!_vm.addDisabled && _vm.balance !== null,"disabled":_vm.addDisabled || _vm.balance == null},on:{"click":function($event){return _vm.upload()}}},on),[_c('v-icon',[_vm._v("mdi-upload")]),(
                      _vm.$vuetify.breakpoint.name != 'xs' && _vm.mode === 'empty'
                    )?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('form.dataGrid.uploadFile')))]):_vm._e(),(
                      _vm.$vuetify.breakpoint.name != 'xs' &&
                        _vm.mode === 'multipleFiles'
                    )?_c('span',{staticClass:"ml-2"},[_vm._v("加入其他檔案")]):_vm._e()],1),_c('input',{ref:"upload",staticClass:"sb-form-formResultuUpload",attrs:{"type":"file","id":"upload","multiple":_vm.multiple,"accept":_vm.fileTypes},on:{"change":function($event){return _vm.changeFile()}}})]}}],null,false,2609914554)},[_c('span',[_vm._v(_vm._s(_vm.$t('form.dataGrid.uploadFile')))])]):_vm._e()],1)],1)]),(_vm.balance == null)?_c('v-layout',{staticStyle:{"background-color":"#eeeeee","height":"calc(85vh - 69px)"},attrs:{"id":"layor","align":"center"}},[_c('LoadingMode')],1):_vm._e(),(_vm.mode === 'empty' && _vm.balance !== null)?_c('v-layout',{staticStyle:{"background-color":"#eeeeee","height":"calc(85vh - 69px)"},attrs:{"id":"layor","align":"center"}},[_c('EmptyMode',{attrs:{"fileTypes":_vm.fileTypes,"multiple":_vm.multiple,"noQuata":_vm.noQuata,"balance":_vm.balance},on:{"changeFile":_vm.changeFile}})],1):_vm._e(),(_vm.mode === 'multipleFiles')?_c('v-layout',{attrs:{"align":"center"}},[_c('v-card',{attrs:{"flat":""}},[_c('MultipleFilesMode',{ref:"multipleMode",attrs:{"companyId":_vm.companyId,"balance":_vm.balance,"elementSize":_vm.elementSize,"filesList":_vm.filesList,"selectedGroup":_vm.selected},on:{"uploadConfirm":_vm.uploadConfirm,"uploadCancelAction":_vm.uploadCancelAction,"showEmpty":_vm.showEmpty,"loadBalance":_vm.loadBalance}})],1)],1):_vm._e()],1):_vm._e(),(_vm.forbidden)?_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('Forbidden403')],1):_vm._e()],1),_c('LoadingDialog',{model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}}),_c('v-dialog',{attrs:{"persistent":"","no-click-animation":"","max-width":"400"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('AddGroupDialog',{on:{"addGroupConfirm":_vm.addGroupConfirm,"addDialogClose":_vm.addDialogClose}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }