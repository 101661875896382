<template>
  <v-card flat style="height:85vh;"
    ><Upload
      :group="group"
      :title="title"
      :companyId="companyId"
      :multiple="multiple"
      :isForm="isForm"
      :fileTypes="fileTypes"
      :allowDownload="allowDownload"
      @uploadConfirm="uploadConfirm"
  /></v-card>
</template>

<script>
import { mapState } from 'vuex';
import Upload from '../../components/Upload/Main.vue';
export default {
  components: {
    Upload,
  },
  data() {
    return {
      group: this.$route.params.group,
      // companyId: this.$route.params.companyId,
      title: '測試',
      multiple: true,
      isForm: false,
      allowDownload: false,
      fileTypes: ['.zip'],
      //fileTypes: [".xlsx", ".xls"],
      //fileTypes: [".pdf"],
    };
  },
  computed: {
    ...mapState('company', {
      companyId: (state) => state.company.id,
    }),
  },
  watch: {
    companyId: {
      handler(val) {
        if (val === undefined || val === null || val === '') {
          this.companyId = this.$route.params.companyId;
        }
      },
      deep: true,
    },
  },
  methods: {
    /* 確認上傳：寫入api **/
    uploadConfirm() {
      // console.log("success~", selectedResults);
    },
  },
};
</script>
