<template>
  <div>
    <v-card>
      <v-card-title>取消上傳</v-card-title>
      <v-card-text v-if="remainRecord"
        >確定要取消上傳資料嗎?
        <br />取消上傳之資料將會保存紀錄至24小時後自動刪除</v-card-text
      >
      <v-card-text v-if="!remainRecord">確定要取消上傳資料嗎? </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="cancelDialogClose()">{{
          $t("form.dataGrid.continueEdit")
        }}</v-btn>
        <v-btn dark color="error" @click="uploadCancelAction()">{{
          $t("form.dataGrid.uploadCancelConfirm")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    remainRecord: {
      //是否保存紀錄至24小時候自動刪除
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      uploadCancelDialog: false,
    };
  },
  methods: {
    cancelDialogClose() {
      this.$emit("cancelDialogClose");
    },
    uploadCancelAction() {
      this.$emit("uploadCancelAction", {
        uploadCancelDialog: this.uploadCancelDialog,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles.scss";
</style>
