var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-0 ma-0",staticStyle:{"height":"calc(85vh - 69px)"},attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-0 ma-0",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{attrs:{"color":"#EEEEEE","elevation":"0","tile":""}},[_c('v-card-text',{staticClass:"pt-3 title",staticStyle:{"height":"50px"}},[_vm._v("待上傳檔案列表")])],1)],1),_c('v-col',{staticClass:"align-end justify-end"},[_c('v-card',{attrs:{"color":"#EEEEEE","elevation":"0","tile":""}},[_c('v-card-text',{staticClass:"pt-3 subtitle-1 ",staticStyle:{"height":"50px","text-align":"end"}},[_vm._v("共 "+_vm._s(_vm.gridResults.total)+" 筆")])],1)],1)],1),_c('localization-provider',{attrs:{"language":_vm.$i18n.locale}},[_c('intl-provider',{attrs:{"locale":_vm.$i18n.locale}},[_c('grid',{ref:"grid",style:(_vm.setKendoheightCheck),attrs:{"id":"multipleUploadGrid","data-items":_vm.gridResults.gridData,"resizable":"","scrollable":"","columns":_vm.columns},on:{"datastatechange":_vm.dataStateChange,"itemchange":_vm.itemChange,"remove":_vm.remove,"retry":_vm.retry}},[_c('grid-no-records',{style:({ minHeight: _vm.gridResults.loading ? '256px' : '128px' })},[(_vm.gridResults.loading)?_c('div',{staticClass:"k-loading-mask"},[_c('span',{staticClass:"k-loading-text"}),_c('div',{staticClass:"k-loading-image"}),_c('div',{staticClass:"k-loading-color"})]):_c('div',[_vm._v(_vm._s(_vm.$t('form.no_records')))])])],1)],1)],1),_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":"","outlined":"","color":"#EEEEEE"}},[_c('v-spacer'),(_vm.notEnoughQuota && !_vm.cannotContinue)?_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('QuotaUploadAlert')],1):_vm._e(),(_vm.finishLoading)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"actionBtn",attrs:{"color":"primary","fab":_vm.$vuetify.breakpoint.name == 'xs',"small":_vm.$vuetify.breakpoint.name == 'xs',"disabled":_vm.cannotContinue,"outlined":""},on:{"click":function($event){return _vm.continueUpload()}}},on),[(_vm.$vuetify.breakpoint.name != 'xs')?_c('span',[_vm._v("繼續上傳")]):_vm._e()])]}}],null,false,620764213)},[_c('span',[_vm._v("繼續上傳")])]):_vm._e(),(!_vm.finishLoading)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"actionBtn",attrs:{"color":"primary","fab":_vm.$vuetify.breakpoint.name == 'xs',"small":_vm.$vuetify.breakpoint.name == 'xs',"outlined":""},on:{"click":function($event){return _vm.uploadCancel()}}},on),[(_vm.$vuetify.breakpoint.name != 'xs')?_c('span',[_vm._v(_vm._s(_vm.$t('form.dataGrid.uploadCancel')))]):_vm._e()])]}}],null,false,3555101090)},[_c('span',[_vm._v(_vm._s(_vm.$t('form.dataGrid.uploadCancel')))])]):_vm._e(),(!_vm.finishLoading)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"actionBtn ml-3",attrs:{"color":"primary","fab":_vm.$vuetify.breakpoint.name == 'xs',"small":_vm.$vuetify.breakpoint.name == 'xs',"depressed":"","disabled":_vm.notEnoughQuota,"loading":_vm.loading},on:{"click":function($event){return _vm.uploadDialogOpen()}}},on),[(_vm.$vuetify.breakpoint.name != 'xs')?_c('span',[_vm._v(_vm._s(_vm.$t('form.dataGrid.uploadConfirm')))]):_vm._e()])]}}],null,false,3173734069)},[_c('span',[_vm._v(_vm._s(_vm.$t('form.dataGrid.uploadConfirm')))])]):_vm._e(),(_vm.finishLoading)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"actionBtn ml-3",attrs:{"color":"primary","fab":_vm.$vuetify.breakpoint.name == 'xs',"small":_vm.$vuetify.breakpoint.name == 'xs',"depressed":""},on:{"click":function($event){return _vm.routeToGroup()}}},on),[(_vm.$vuetify.breakpoint.name != 'xs')?_c('span',[_vm._v("前往模型列表")]):_vm._e()])]}}],null,false,2006266073)},[_c('span',[_vm._v("前往模型列表")])]):_vm._e(),(_vm.finishLoading)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"actionBtn ml-3",attrs:{"color":"primary","fab":_vm.$vuetify.breakpoint.name == 'xs',"small":_vm.$vuetify.breakpoint.name == 'xs',"depressed":""},on:{"click":function($event){return _vm.routeToState()}}},on),[(_vm.$vuetify.breakpoint.name != 'xs')?_c('span',[_vm._v("前往模型轉檔")]):_vm._e()])]}}],null,false,712644115)},[_c('span',[_vm._v("前往模型轉檔")])]):_vm._e()],1)],1),_c('v-dialog',{attrs:{"content-class":"alertDialog","max-width":"330"},model:{value:(_vm.uploadDialog),callback:function ($$v) {_vm.uploadDialog=$$v},expression:"uploadDialog"}},[_c('UploadWarningDialog',{attrs:{"balance":_vm.balance,"uploadCount":_vm.gridResults.total},on:{"uploadDialogClose":function($event){_vm.uploadDialog = false},"uploadConfirm":_vm.uploadConfirm}})],1),_c('v-dialog',{attrs:{"content-class":"alertDialog","max-width":"280"},model:{value:(_vm.uploadCancelDialog),callback:function ($$v) {_vm.uploadCancelDialog=$$v},expression:"uploadCancelDialog"}},[_c('UploadCancelDialog',{attrs:{"remainRecord":false},on:{"cancelDialogClose":function($event){_vm.uploadCancelDialog = false},"uploadCancelAction":_vm.uploadCancelAction}})],1),_c('LoadingDialog',{model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }