<template>
  <div>
    <v-card>
      <v-card-title>新增群組</v-card-title>
      <v-divider />
      <v-text-field
        v-model="groupName"
        label="群組名稱"
        hide-details
        class="ma-6"
        @click.stop
      ></v-text-field>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="addDialogClose()">{{
          $t("form.actions.cancel")
        }}</v-btn>
        <v-btn dark color="primary" @click="addGroupConfirm()">
          {{ $t("form.actions.ok") }}新增</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      groupName: "",
    };
  },
  methods: {
    addDialogClose() {
      this.$emit("addDialogClose");
    },
    addGroupConfirm() {
      if (this.groupName.trim() !== "" && this.groupName !== undefined) {
        this.$emit("addGroupConfirm", this.groupName);
      }
      this.groupName = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles.scss";
</style>
