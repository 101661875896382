<template>
  <v-card flat class="pa-0 ma-0" style="height: calc(85vh - 69px)">
    <v-container fluid class="pa-0 ma-0" style="height:100%">
      <v-row no-gutters>
        <v-col cols="3">
          <v-card color="#EEEEEE" elevation="0" tile>
            <v-card-text class="pt-3 title" style="height: 50px;"
              >待上傳檔案列表</v-card-text
            ></v-card
          >
        </v-col>
        <v-col class="align-end justify-end">
          <v-card color="#EEEEEE" elevation="0" tile>
            <v-card-text
              class="pt-3 subtitle-1 "
              style="height: 50px;text-align:end"
              >共 {{ gridResults.total }} 筆</v-card-text
            ></v-card
          >
        </v-col>
      </v-row>
      <localization-provider :language="$i18n.locale">
        <intl-provider :locale="$i18n.locale">
          <grid
            id="multipleUploadGrid"
            ref="grid"
            :data-items="gridResults.gridData"
            resizable
            scrollable
            :columns="columns"
            @datastatechange="dataStateChange"
            @itemchange="itemChange"
            @remove="remove"
            @retry="retry"
            :style="setKendoheightCheck"
          >
            <grid-no-records
              :style="{ minHeight: gridResults.loading ? '256px' : '128px' }"
            >
              <div v-if="gridResults.loading" class="k-loading-mask">
                <span class="k-loading-text"></span>
                <div class="k-loading-image" />
                <div class="k-loading-color" />
              </div>
              <div v-else>{{ $t('form.no_records') }}</div>
            </grid-no-records>
          </grid>
        </intl-provider>
      </localization-provider>
      <v-sheet height="64">
        <v-toolbar flat outlined color="#EEEEEE">
          <v-spacer></v-spacer>
          <v-card
            v-if="notEnoughQuota && !cannotContinue"
            flat
            color="transparent"
          >
            <QuotaUploadAlert
          /></v-card>
          <v-tooltip top v-if="finishLoading">
            <template v-slot:activator="{ on }">
              <v-btn
                class="actionBtn"
                color="primary"
                :fab="$vuetify.breakpoint.name == 'xs'"
                :small="$vuetify.breakpoint.name == 'xs'"
                :disabled="cannotContinue"
                outlined
                v-on="on"
                @click="continueUpload()"
                ><span v-if="$vuetify.breakpoint.name != 'xs'">繼續上傳</span>
              </v-btn>
            </template>
            <span>繼續上傳</span>
          </v-tooltip>
          <v-tooltip top v-if="!finishLoading">
            <template v-slot:activator="{ on }">
              <v-btn
                class="actionBtn"
                color="primary"
                :fab="$vuetify.breakpoint.name == 'xs'"
                :small="$vuetify.breakpoint.name == 'xs'"
                outlined
                v-on="on"
                @click="uploadCancel()"
                ><span v-if="$vuetify.breakpoint.name != 'xs'">{{
                  $t('form.dataGrid.uploadCancel')
                }}</span>
              </v-btn>
            </template>
            <span>{{ $t('form.dataGrid.uploadCancel') }}</span>
          </v-tooltip>
          <v-tooltip top v-if="!finishLoading">
            <template v-slot:activator="{ on }">
              <v-btn
                class="actionBtn ml-3"
                color="primary"
                :fab="$vuetify.breakpoint.name == 'xs'"
                :small="$vuetify.breakpoint.name == 'xs'"
                depressed
                :disabled="notEnoughQuota"
                v-on="on"
                :loading="loading"
                @click="uploadDialogOpen()"
                ><span v-if="$vuetify.breakpoint.name != 'xs'">{{
                  $t('form.dataGrid.uploadConfirm')
                }}</span>
              </v-btn>
            </template>
            <span>{{ $t('form.dataGrid.uploadConfirm') }}</span>
          </v-tooltip>

          <v-tooltip top v-if="finishLoading">
            <template v-slot:activator="{ on }">
              <v-btn
                class="actionBtn ml-3"
                color="primary"
                :fab="$vuetify.breakpoint.name == 'xs'"
                :small="$vuetify.breakpoint.name == 'xs'"
                depressed
                v-on="on"
                @click="routeToGroup()"
                ><span v-if="$vuetify.breakpoint.name != 'xs'"
                  >前往模型列表</span
                >
              </v-btn>
            </template>
            <span>前往模型列表</span>
          </v-tooltip>
          <v-tooltip top v-if="finishLoading">
            <template v-slot:activator="{ on }">
              <v-btn
                class="actionBtn ml-3"
                color="primary"
                :fab="$vuetify.breakpoint.name == 'xs'"
                :small="$vuetify.breakpoint.name == 'xs'"
                depressed
                v-on="on"
                @click="routeToState()"
                ><span v-if="$vuetify.breakpoint.name != 'xs'"
                  >前往模型轉檔</span
                >
              </v-btn>
            </template>
            <span>前往模型轉檔</span>
          </v-tooltip>
        </v-toolbar>
      </v-sheet>
      <!-- 上傳確認dialog -->
      <v-dialog
        v-model="uploadDialog"
        content-class="alertDialog"
        max-width="330"
      >
        <UploadWarningDialog
          :balance="balance"
          :uploadCount="gridResults.total"
          @uploadDialogClose="uploadDialog = false"
          @uploadConfirm="uploadConfirm"
        />
      </v-dialog>
      <!-- 取消上傳dialog -->
      <v-dialog
        v-model="uploadCancelDialog"
        content-class="alertDialog"
        max-width="280"
      >
        <UploadCancelDialog
          :remainRecord="false"
          @cancelDialogClose="uploadCancelDialog = false"
          @uploadCancelAction="uploadCancelAction"
        />
      </v-dialog>
      <LoadingDialog v-model="loadingDialog" />
    </v-container>
  </v-card>
</template>

<script>
// import Vue from "vue";
import { cloneDeep } from 'lodash';
import ApiConfig from '../../plugins/ApiConfig.js';
import UploadCancelDialog from '../Dialog/UploadCancelDialog.vue';
import UploadWarningDialog from '../Dialog/UploadWarningDialog';
import LoadingDialog from '../Dialog/LoadingDialog.vue';
import FormRemoveCell from '../GridCell/FormRemoveCell.vue';
import UploadStateCell from '../GridCell/UploadStateCell.vue';
import QuotaUploadAlert from './QuotaUploadAlert';

export default {
  components: {
    LoadingDialog,
    UploadCancelDialog,
    UploadWarningDialog,
    QuotaUploadAlert,
  },
  props: {
    companyId: {
      type: String,
    },
    selectedGroup: {
      type: Object,
      default: () => {},
    },
    elementSize: {
      type: Object,
      default: () => {},
    },
    filesList: {
      type: Array,
    },
    balance: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      loadingDialog: false,
      finishLoading: false,
      uploadCancelDialog: false,
      uploadDialog: false,
      notEnoughQuota: false,
      cannotContinue: false,
      firstFileGroup: null,
      formId: '',
      myColumns: [
        { field: 'name', title: '檔名' },
        { field: 'date', title: '修改日期', width: '230px' },
        { field: 'size', title: '大小', width: '130px' },
      ],
      gridResults: {
        loading: false,
        list: [],
        total: 0,
        updatedData: [],
        gridData: {
          data: [],
          total: 0,
        },
        dataState: {
          take: 20,
          skip: 0,
          sort: [],
          filter: null,
          group: '',
        },
        // pageable: {
        //   buttonCount: 5,
        //   info: true,
        //   type: "numeric",
        //   pageSizes: [10, 20, 50],
        //   previousNext: true,
        // },
      },
      // uploadcheck: {
      //   title: "是否上傳",
      //   field: "selected",
      //   headerSelectionValue: this.areAllSelected,
      //   width: "70px",
      //   minResizableWidth: "70",
      //   locked: true,
      // },
      checkAllBtn: false,
      hiddenUpload: true,
    };
  },
  watch: {
    gridResults: {
      handler(val) {
        if (val.gridData.data.length == 0) {
          this.$emit('showEmpty');
        }
        var count = 0;
        for (let i = 0; i < val.gridData.data.length; i++) {
          if (
            val.gridData.data[i].state == 'success' ||
            val.gridData.data[i].state == 'failed'
          ) {
            count += 1;
          }
        }
        if (count == val.gridData.data.length) {
          this.loading = false;
          this.finishLoading = true;
        } else {
          count = 0;
        }
        //計算此次上傳的餘額
        if (val.total > this.balance) {
          this.notEnoughQuota = true;
        }
        if (val.total <= this.balance) {
          this.notEnoughQuota = false;
        }
      },
      deep: true,
    },
    balance: {
      handler(val) {
        console.log('balance', val);
        //計算上傳後的餘額
        if (this.gridResults.total > val) {
          this.cannotContinue = true;
        }
        if (this.gridResults.total <= val) {
          this.cannotContinue = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    columns() {
      return [
        ...this.myColumns,
        {
          title: '移除',
          field: 'remove',
          width: '78px',
          hidden: !this.hiddenUpload,
          minResizableWidth: 78,
          cell: FormRemoveCell,
        },
        {
          title: '上傳狀態',
          field: 'uploadState',
          width: '110px',
          hidden: this.hiddenUpload,
          minResizableWidth: 78,
          cell: UploadStateCell,
        },
      ];
    },
    result: {
      get: function() {
        var data = this.gridResults.gridData.data;
        return data.slice(
          this.gridResults.dataState.skip,
          this.gridResults.dataState.take + this.gridResults.dataState.skip
        );
      },
    },
    // areAllSelected() {
    //   return (
    //     this.gridResults.gridData.data.findIndex(
    //       (item) => item.selected === false
    //     ) === -1
    //   );
    // },
    setKendoheightCheck() {
      var height = this.elementSize.height - 164 - 19;
      return `height:${height}px`;
    },
  },
  mounted() {
    this.loadDatas();
  },
  methods: {
    loadDatas() {
      this.resultClear();
      this.gridResults.loading = true;
      this.$data.gridResults.total = this.filesList.length;
      this.$data.gridResults.list = cloneDeep(this.filesList);
      this.processData();

      this.gridResults.loading = false;
    },
    reload(filesList) {
      this.resultClear();
      this.gridResults.loading = true;

      this.$data.gridResults.total = filesList.length;
      this.$data.gridResults.list = cloneDeep(filesList);
      this.processData();

      this.gridResults.loading = false;
    },
    resultClear() {
      this.gridResults.gridData = {
        data: [],
        total: 0,
      };
    },
    itemChange(e) {
      if (e.dataItem.id) {
        let item = this.gridResults.list.find((p) => p.id === e.dataItem.id);
        this.$set(item, e.field, e.value);
      } else {
        this.$set(e.dataItem, e.field, e.value);
      }
      this.processData();
    },
    processData() {
      this.gridResults.gridData.data = this.gridResults.list;
      this.gridResults.gridData.total = this.gridResults.total;
    },
    handleChange(ev) {
      var value = ev.value;
      var field = ev.field;
      this.gridResults.dataState.filter = {
        filters: [
          {
            logic: 'or',
            filters: [
              {
                field: field,
                operator: 'contains',
                value: value.trim(),
              },
            ],
          },
        ],
        logic: 'or',
      };
    },
    dataStateChange(event) {
      var dataState = event.data;
      this.gridResults.dataState.take = dataState.take;
      this.gridResults.dataState.skip = dataState.skip;
      this.gridResults.dataState.sort = dataState.sort;
      this.gridResults.dataState.filter = this.filterDataToUTC(
        dataState.filter
      );
      this.checkAllBtn = false;
      this.countSelect();
    },
    //  kendo date 轉換
    filterDataToUTC(filter) {
      if (filter == null) {
        return filter;
      }
      var filters = filter.filters;
      if (filters == null || filters.length == 0) {
        return filter;
      }
      filters.forEach((f) => {
        f.filters.forEach((ff) => {
          if (Object.prototype.toString.call(ff.value) === '[object Date]') {
            ff.value = ff.value.toUTCString();
          }
        });
      });
      return filter;
    },
    /**移除上傳檔案 */
    remove(e) {
      var data = this.gridResults.gridData.data;
      for (let i = 0; i < data.length; i++) {
        if (data[i] == e.dataItem) {
          this.gridResults.list.splice(i, 1);
          this.gridResults.total = this.gridResults.list.length;
          this.processData();
        }
      }
    },
    //取消上傳按鈕
    uploadCancel() {
      this.uploadCancelDialog = true;
    },
    uploadDialogOpen() {
      this.uploadDialog = true;
    },
    /**確認上傳 */
    uploadConfirm() {
      this.uploadDialog = false;
      this.loading = true;
      this.hiddenUpload = false;
      this.$emit('uploadConfirm', this.gridResults.gridData.data);

      //判斷是否是未分類群組(id==null)
      // console.log("this.selectedGroup", this.selectedGroup);
      if (this.selectedGroup.id == null) {
        this.uploadDefaultGroup();
      } else {
        this.uploadGroupItems();
      }
    },
    /**上傳到一般群組 */
    uploadGroupItems() {
      var uploadData = this.gridResults.gridData.data;
      for (let i = 0; i < uploadData.length; i++) {
        var file = uploadData[i].file;
        let formData = new FormData();
        formData.append('file', file);
        var groupId = this.selectedGroup.id;

        this.$API.api.pc.productGroupItem
          .post(groupId, formData, ApiConfig.getJsonConfig)
          // .post(groupId, formData)
          .then((res) => {
            //res中的data為_guid
            //this.importId = res.data;
            if (i === 0) {
              this.firstFileGroup = res.data.groupId;
            }

            this.gridResults.gridData.data[i].state = 'success';
          })
          .catch((error) => {
            console.error(error);
            this.gridResults.gridData.data[i].state = 'failed';
            this.$store.commit('notification/SET_DIALOG', {
              title: 'Error',
              message: error,
              value: true,
            });
          })
          .finally(() => {
            this.$emit('loadBalance');
            // if (i == uploadData.length - 1) {
            //   this.loading = false;
            //   this.finishLoading = true;
            // }
          });
      }
    },
    /**上傳到預設群組 */
    uploadDefaultGroup() {
      var uploadData = this.gridResults.gridData.data;
      for (let i = 0; i < uploadData.length; i++) {
        var file = uploadData[i].file;
        let formData = new FormData();
        formData.append('file', file);
        this.$API.api.pc.companyDefaultProductGroup
          .post(this.companyId, formData, ApiConfig.getJsonConfig)
          .then((res) => {
            // console.log("res", res);
            this.gridResults.gridData.data[i].state = 'success';
            if (i === 0) {
              this.firstFileGroup = res.data.groupId;
            }
          })
          .catch((error) => {
            console.error(error);
            this.gridResults.gridData.data[i].state = 'failed';
            this.$store.commit('notification/SET_DIALOG', {
              title: 'Error',
              message: error,
              value: true,
            });
          })
          .finally(() => {
            this.$emit('loadBalance');
            // if ((i = uploadData.length - 1)) {
            //   this.loading = false;
            //   this.finishLoading = true;
            // } else if (uploadData.length == 1 && i == 0) {
            //   this.loading = false;
            //   this.finishLoading = true;
            // }
          });
      }
    },
    /**重試上傳 */
    retry(e) {
      if (this.selectedGroup.id == null) {
        this.reloadDefaultGroup(e.dataItem);
      } else {
        this.reloadGroupItems(e.dataItem);
      }
    },
    reloadGroupItems(item) {
      item.state = 'loading';
      var file = item.file;
      let formData = new FormData();
      formData.append('file', file);
      var groupId = this.selectedGroup.id;

      this.$API.api.pc.productGroupItem
        .post(groupId, formData, ApiConfig.getJsonConfig)
        .then(() => {
          item.state = 'success';
        })
        .catch((error) => {
          console.error(error);
          item.state = 'failed';
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {});
    },
    reloadDefaultGroup(item) {
      item.state = 'loading';
      var file = item.file;
      let formData = new FormData();
      formData.append('file', file);
      this.$API.api.pc.companyDefaultProductGroup
        .post(this.companyId, formData, ApiConfig.getJsonConfig)
        .then(() => {
          item.state = 'success';
        })
        .catch((error) => {
          console.error(error);
          item.state = 'failed';
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {});
    },
    /**跳轉到模型列表*/
    routeToGroup() {
      this.$router.push({
        name: 'ManagePage',
        params: {
          group: this.firstFileGroup,
          companyId: this.companyId,
        },
      });
    },
    routeToState() {
      this.$router.push({
        name: 'UploadState',
        params: {
          companyId: this.companyId,
        },
      });
    },
    /**確認取消上傳*/
    uploadCancelAction() {
      this.$emit('uploadCancelAction');
    },
    continueUpload() {
      this.$emit('uploadCancelAction');
    },
  },
};
</script>
