<template>
  <td style="margin: 0">
    <v-btn class="mr-2" icon @click="removeHandler"
      ><v-icon>mdi-close</v-icon></v-btn
    >
  </td>
</template>

<script>
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  methods: {
    removeHandler() {
      this.$emit("remove", { dataItem: this.dataItem });
    },
  },
};
</script>

<style></style>
