<template>
  <v-layout wrap>
    <v-flex sm12 md12>
      <v-card outlined style="height: 85vh;" v-if="!forbidden">
        <div>
          <div id="cardTitle" v-resize="onResize">
            <v-card-title class="sb-form-cardTitle">
              <v-icon class="pr-3">mdi-cloud-upload-outline</v-icon>
              <div v-if="!isPage">模型上傳</div>
              <v-icon>mdi-chevron-right</v-icon>{{ selectedGName }}
              <v-menu offset-y left v-if="!uploading">
                <template v-slot:activator="{ on }">
                  <v-btn small icon v-on="on" class="ml-1">
                    <v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>

                <v-list subheader flat class="pa-0" dense>
                  <v-subheader>選擇上傳群組</v-subheader>
                  <v-divider />
                  <v-card
                    max-height="300"
                    min-width="140"
                    style="overflow-y: scroll;"
                  >
                    <v-list-item-group
                      v-model="selectedGroup"
                      color="primary"
                      active-class="groupMenuActive"
                    >
                      <template v-for="item in myGroups">
                        <v-list-item :key="item.id">
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list-item-group></v-card
                  >
                </v-list>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-fab-transition>
                      <v-btn
                        color="primary"
                        class="mb-5 mr-1"
                        fab
                        dark
                        x-small
                        absolute
                        bottom
                        right
                        :elevation="1"
                        v-on="on"
                        @click="addGroup()"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                  <span>新增群組</span>
                </v-tooltip>
              </v-menu>
              <v-spacer></v-spacer>
              <v-card flat v-if="balance !== null && balance < 10">
                <QuotaAlert :balance="balance" />
              </v-card>
              <v-tooltip bottom v-if="mode !== 'check'">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="actionBtn"
                    color="primary"
                    :fab="$vuetify.breakpoint.name == 'xs'"
                    :small="$vuetify.breakpoint.name == 'xs'"
                    depressed
                    :dark="!addDisabled && balance !== null"
                    v-on="on"
                    :disabled="addDisabled || balance == null"
                    @click="upload()"
                    ><v-icon>mdi-upload</v-icon
                    ><span
                      class="ml-2"
                      v-if="
                        $vuetify.breakpoint.name != 'xs' && mode === 'empty'
                      "
                      >{{ $t('form.dataGrid.uploadFile') }}</span
                    ><span
                      class="ml-2"
                      v-if="
                        $vuetify.breakpoint.name != 'xs' &&
                          mode === 'multipleFiles'
                      "
                      >加入其他檔案</span
                    ></v-btn
                  ><input
                    type="file"
                    class="sb-form-formResultuUpload"
                    id="upload"
                    ref="upload"
                    :multiple="multiple"
                    @change="changeFile()"
                    :accept="fileTypes"
                  />
                </template>
                <span>{{ $t('form.dataGrid.uploadFile') }}</span>
              </v-tooltip>
            </v-card-title>
          </div>
        </div>
        <!-- mode 取得 balance 前的 loading -->
        <v-layout
          id="layor"
          align="center"
          style="background-color: #eeeeee; height: calc(85vh - 69px);"
          v-if="balance == null"
        >
          <LoadingMode />
        </v-layout>
        <!-- mode = "empty"：沒有任何待上傳檔案的狀態 -->
        <v-layout
          id="layor"
          align="center"
          style="background-color: #eeeeee; height: calc(85vh - 69px);"
          v-if="mode === 'empty' && balance !== null"
        >
          <EmptyMode
            :fileTypes="fileTypes"
            :multiple="multiple"
            :noQuata="noQuata"
            :balance="balance"
            @changeFile="changeFile"
          />
        </v-layout>
        <!-- mode = "multipleFiles"：選擇上傳多筆資料後確認上傳的狀態 -->
        <v-layout align="center" v-if="mode === 'multipleFiles'">
          <v-card flat>
            <MultipleFilesMode
              ref="multipleMode"
              :companyId="companyId"
              :balance="balance"
              :elementSize="elementSize"
              :filesList="filesList"
              :selectedGroup="selected"
              @uploadConfirm="uploadConfirm"
              @uploadCancelAction="uploadCancelAction"
              @showEmpty="showEmpty"
              @loadBalance="loadBalance"
          /></v-card>
        </v-layout>
      </v-card>
      <v-card flat color="transparent" v-if="forbidden"
        ><Forbidden403
      /></v-card>
    </v-flex>
    <LoadingDialog v-model="loadingDialog" />
    <v-dialog v-model="addDialog" persistent no-click-animation max-width="400">
      <AddGroupDialog
        @addGroupConfirm="addGroupConfirm"
        @addDialogClose="addDialogClose"
      />
    </v-dialog>
  </v-layout>
</template>

<script>
import ApiConfig from '../../plugins/ApiConfig';
import DateFormat from '../../plugins/DateFormat.js';
import LoadingDialog from '../Dialog/LoadingDialog.vue';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import EmptyMode from './EmptyMode.vue';
import LoadingMode from './LoadingMode.vue';
import MultipleFilesMode from './MultipleFilesMode.vue';
import AddGroupDialog from '../Dialog/AddGroupDialog';
import QuotaAlert from './QuotaAlert';
import Forbidden403 from '../SystemPage/Forbidden403';
export default {
  components: {
    LoadingDialog,
    EmptyMode,
    LoadingMode,
    MultipleFilesMode,
    AddGroupDialog,
    QuotaAlert,
    Forbidden403,
  },
  props: {
    title: {
      type: String,
    },
    fileTypes: {
      type: Array,
    },
    multiple: {
      type: Boolean,
    },
    isForm: {
      type: Boolean,
    },
    isPage: {
      type: Boolean,
      default: false,
    }, // 是否用在page
    size: {
      type: Object,
      default: () => {},
    },
    companyId: {
      type: String,
    },
    group: {
      type: Object,
    },
  },
  data() {
    return {
      balance: null,
      formId: '',
      token: '',
      importId: '',
      mode: 'empty',
      loadingDialog: false,
      elementSize: { width: 0, height: 0 },
      selectedResults: [],

      file: '',
      files: '',
      filesList: [],

      uploadAllCheck: false,
      checkText: '選取所有資料',
      //伍全
      groups: [],
      myGroups: [{ name: '未分類群組', id: null }],
      selectedGroup: 0,
      selectedGName: '未分類群組',
      selected: {},
      dataState: {
        page: 0,
        sort: [{ field: 'name', dir: 'asc' }],
      },
      uploading: false,
      addDialog: false,
      addNew: false,
      addNewName: '',
      addDisabled: false,
      noQuata: false,
      forbidden: false,
    };
  },
  computed: {
    areAllSelected() {
      return (
        this.gridResults.gridData.data.findIndex(
          (item) => item.selected === false
        ) === -1
      );
    },
    cols() {
      const { lg, sm } = this.$vuetify.breakpoint;
      return lg ? [3, 9] : sm ? [9, 3] : [6, 6];
    },
  },
  watch: {
    selectedGroup: {
      handler() {
        this.getGroupName();
      },
      deep: true,
    },
    companyId: {
      handler() {
        this.myGroups = [{ name: '未分類群組', id: null }];
        this.loadGroups();
        this.loadBalance();
        this.modeCheck();
      },
      deep: true,
    },
  },
  created() {
    this.loadGroups();
  },
  mounted() {
    this.loadBalance();
    this.modeCheck();
    //this.setSelected();
  },
  methods: {
    /**讀取剩餘額度 */
    async loadBalance() {
      var companyId = this.companyId;
      var start = null;
      var end = null;
      var res = await this.$API.api.pc.companyProcessingUsage.getBalance(
        companyId,
        start,
        end,
        ApiConfig.getJsonConfig
      );
      this.balance = res.data;
      if (this.balance <= 0) {
        this.addDisabled = true;
        this.noQuata = true;
      }
    },
    /**讀取所有群組 */
    async loadGroups() {
      var companyId = this.companyId;
      var dataState = this.dataState;
      const queryStr = toDataSourceRequestString(dataState);
      var urlencoded = new URLSearchParams(queryStr);

      return new Promise((resolve) => {
        this.$API.api.pc.companyProductGroup
          .get(companyId, null, urlencoded, ApiConfig.getJsonConfig)
          .then((res) => {
            var data = res.data.data;
            this.groups = data;
            for (let i = 0; i < this.groups.length; i++) {
              this.myGroups.push(this.groups[i]);
            }
            this.setSelected();
            this.getGroupName();
          })
          .catch((error) => {
            console.error(error);
            if (error.response.status === 403) {
              this.forbidden = true;
            }
          })
          .finally(() => {
            resolve();
          });
      });
    },
    setSelected() {
      for (let i = 0; i < this.groups.length; i++) {
        if (this.addNew) {
          if (this.addNewName == this.groups[i].name) {
            this.selectedGroup = i + 1;
          }
        } else if (this.group !== undefined) {
          if (this.group.id == this.groups[i].id) {
            this.selectedGroup = i + 1;
          }
        }
      }
    },
    getGroupName() {
      this.selectedGName = this.myGroups[this.selectedGroup].name;
      this.selected = this.myGroups[this.selectedGroup];
    },
    onResize() {
      this.elementSize.height = this.$parent.$el.scrollHeight;
      this.elementSize.width = this.$parent.$el.scrollWidth;
    },

    //判斷表單模式，有紀錄就是record mode，無紀錄就是empty mode
    modeCheck() {
      //this.dragging = false;
      if (this.isForm) {
        var formId = this.formId;
        this.$API.api.main.formImportResult
          .getImportResult(formId, ApiConfig.getJsonConfig)
          .then((res) => {
            if (res.data.length === 0) {
              this.mode = 'empty';
            } else {
              this.mode = 'record';
            }
          })
          .catch((error) => {
            console.error(error);
            this.$store.commit('notification/SET_DIALOG', {
              title: 'Error',
              message: error,
              value: true,
            });
          })
          .finally(() => {});
      }
    },
    //upload 按鈕 (trigger input 上傳按鈕)
    upload() {
      let uploadbtn = this.$refs.upload;
      uploadbtn.click();
    },
    //上傳檔案至後端
    changeFile() {
      //上傳多個檔案頁面時要再進行上傳動作
      if (this.mode == 'multipleFiles') {
        var files =
          document.querySelector('input[type=file]').files ||
          event.target.files ||
          event.dataTransfer.files;
        var newFiles = [];
        for (let i = 0; i < files.length; i++) {
          newFiles.push(files[i]);
        }

        if (newFiles.length > 0) {
          for (let i = 0; i < newFiles.length; i++) {
            for (let j = 0; j < this.files.length; j++) {
              if (newFiles[i].name == this.files[j].name) {
                newFiles.splice(i, 1);
              }
            }
          }
          for (let i = 0; i < newFiles.length; i++) {
            this.files.push(newFiles[i]);
          }
        }
        this.getFilesList();
        this.$refs.multipleMode.reload(this.filesList);
      }
      //在無檔案的狀態下上傳多個檔案
      if (this.multiple && this.mode == 'empty') {
        //let formData = new FormData();
        //Empty Mode 無法讀取多個檔案(暫時解不出來的bug，用forloop處理)
        var tempFiles = [];
        for (let i = 0; i < 100; i++) {
          if (
            document.querySelector('input[type=file]').files[i] ||
            event.target.files[i]
          ) {
            var file =
              document.querySelector('input[type=file]').files[i] ||
              event.target.files[i] ||
              event.dataTransfer.files[i];
            tempFiles.push(file);
          }
        }
        this.files = tempFiles;

        // this.files =
        //   document.querySelector("input[type=file]").files ||
        //   event.target.files ||
        //   event.dataTransfer.files;
        //console.log("files", this.files);
        this.getFilesList();

        this.mode = 'multipleFiles';
      } else if (!this.multiple && this.isForm) {
        this.loadingDialog = true;
        let formData = new FormData();
        file =
          document.querySelector('input[type=file]').files[0] ||
          event.target.files[0] ||
          event.dataTransfer.files[0];
        formData.append('file', file);

        //console.log("changeFile");
        var formId = this.formId;
        this.$API.api.main.formSpreadSheet
          .post(formId, formData, ApiConfig.getJsonConfig)
          .then((res) => {
            //res中的data為_guid
            this.importId = res.data;
            //console.log("importId", this.importId);
            this.mode = 'check';
            //this.loadCheckDatas(this.importId);
            //console.log("check");
          })
          .catch((error) => {
            console.error(error);
            this.$store.commit('notification/SET_DIALOG', {
              title: 'Error',
              message: error,
              value: true,
            });
          })
          .finally(() => {
            this.loadingDialog = false;
          });
      }
    },
    errorDialogClose() {
      this.modeCheck();
    },
    //整理filesList
    getFilesList() {
      if (this.files) {
        var temp = [];
        for (let i = 0; i < this.files.length; i++) {
          if (
            this.files[i].type == 'application/zip' ||
            this.files[i].type == 'application/x-zip-compressed'
          ) {
            var date = new Date(this.files[i].lastModified);
            var t = {
              name: this.files[i].name,
              size: this.formatBytes(this.files[i].size),
              date: DateFormat.dateForVuetify(date.toString(), true, true),
              file: this.files[i],
              state: 'loading',
            };
            temp.push(t);
          }
        }
        this.filesList = temp;
      }
    },
    //撈取上傳待檢視確認之資料
    loadCheckDatas(id) {
      //this.resultClear();
      var importId = id;
      this.gridResults.loading = true;
      const queryStr = toDataSourceRequestString(this.gridResults.dataState); // Serialize the state
      var urlencoded = new URLSearchParams(queryStr);
      this.$API.api.main.formImportResult
        .post(importId, urlencoded, ApiConfig.getJsonConfig)
        .then((res) => {
          this.$data.gridResults.total = res.data.data.length;

          let items = res.data.data;
          this.$data.gridResults.list = items;
          this.gridResults.loading = false;
          this.processData();
          this.$data.gridResults.gridData.data = this.$data.gridResults.gridData.data.map(
            (item) => {
              return { ...item, selected: true };
            }
          );
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.gridResults.loading = false;
        });
    },
    //確認上傳
    uploadConfirm(uploadFile) {
      this.$emit('uploadConfirm', uploadFile);
      this.uploading = true;
      this.addDisabled = true;
    },
    //確認取消上傳
    uploadCancelAction() {
      this.addDisabled = false;
      this.uploading = false;
      if (this.mode == 'check') {
        this.mode = 'record';
        this.modeCheck();
      } else if (this.mode == 'multipleFiles') {
        this.mode = 'empty';
      }
      //this.resultClear();
    },
    //點擊檢視表單，切換到check模式
    checkAction(e) {
      this.importId = e.dataItem.id;
      this.mode = 'check';
    },
    advancedClick(e) {
      if (e.module === 'uploadAll') {
        this.selectedResults = [];
        this.uploadConfirm();
      }
    },
    formatBytes(bytes, decimals) {
      if (bytes == 0) return '0 Bytes';
      var k = 1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    showEmpty() {
      this.mode = 'empty';
    },
    /**新增群組 */
    addGroup() {
      this.addDialog = true;
    },
    addGroupConfirm(groupName) {
      this.addNew = true;
      this.addNewName = groupName;
      this.addDialog = false;
      return new Promise((resolve) => {
        this.$API.api.pc.companyProductGroup
          .post(this.companyId, groupName, ApiConfig.getJsonConfig)
          .then(() => {})
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.myGroups = [{ name: '未分類群組', id: null }];
            this.loadGroups();
            resolve();
          });
      });
    },
    addDialogClose() {
      this.addDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
.sb-form-formResultuUpload {
  height: 0px !important;
  width: 0px !important;
  visibility: hidden !important;
}
.sb-form-cardTitle {
  padding: 16px;
  padding-bottom: 15px !important;
}
.groupMenuActive {
  background-color: #e2eefc;
}
</style>
