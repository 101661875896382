<template>
  <v-card flat>
    <v-alert text border="left" color="error" class="pt-1 pb-0 ma-0">
      <v-icon color="error" class="mr-2" style="margin-top: -5px"
        >mdi-alert</v-icon
      >剩餘點數
      <strong>{{ balance }} 點</strong>
    </v-alert></v-card
  >
</template>

<script>
export default {
  props: {
    balance: {
      type: Number,
    },
  },
};
</script>
