var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:[
    'dropZone',
    _vm.dragging ? 'dropZone-over' : '',
    _vm.noQuata ? 'zoneNoQuota' : '' ],attrs:{"id":"dragOuter","elevation":"5"},on:{"dragenter":function($event){return _vm.dragenter()},"dragleave":function($event){_vm.dragging = false}}},[_c('v-card',{class:[
      'dropZone-info',
      _vm.dragging ? 'dropZone-infoover' : '',
      _vm.noQuata ? 'zoneNoQuota-infoover' : '' ],attrs:{"elevation":"0"},on:{"drag":function($event){return _vm.changeFile()}}},[_c('v-card-text',{staticStyle:{"height":"80px","position":"relative"}}),_c('v-card-text',{staticClass:"text-center align-center",staticStyle:{"position":"relative"}},[_c('v-container',{staticClass:"pa-0 ma-0",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-img',{staticClass:"text-center",style:(_vm.noQuata
                  ? '-webkit-filter: grayscale(100%);filter: grayscale(100%); opacity: 0.8;'
                  : ''),attrs:{"lazy-src":"/upload.svg","src":"/upload.svg","max-width":"450","contain":""}})],1)],1)],1),(!_vm.noQuata)?_c('v-card-text',{staticClass:"text-center display-1",staticStyle:{"height":"50px"}},[_vm._v(" "+_vm._s(_vm.$t('form.dataGrid.dropFile'))+" ")]):_vm._e(),(!_vm.noQuata)?_c('v-card-text',{staticClass:"text-center headline"},[_vm._v(_vm._s(_vm.$t('form.dataGrid.uploadButtonUse')))]):_vm._e(),(_vm.noQuata)?_c('v-card-text',{staticClass:"text-center display-1",staticStyle:{"height":"50px"}},[_vm._v(" 餘額不足 ")]):_vm._e(),(_vm.noQuata)?_c('v-card-text',{staticClass:"text-center headline"},[_vm._v("請進行加值")]):_vm._e()],1)],1),(!_vm.noQuata && _vm.balance !== null)?_c('input',{ref:"upload",attrs:{"type":"file","id":"upload","multiple":_vm.multiple,"accept":_vm.fileTypes},on:{"change":function($event){return _vm.changeFile()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }