<template>
  <v-card
    id="dragOuter"
    elevation="5"
    :class="[
      'dropZone',
      dragging ? 'dropZone-over' : '',
      noQuata ? 'zoneNoQuota' : '',
    ]"
    @dragenter="dragenter()"
    @dragleave="dragging = false"
  >
    <v-card
      elevation="0"
      :class="[
        'dropZone-info',
        dragging ? 'dropZone-infoover' : '',
        noQuata ? 'zoneNoQuota-infoover' : '',
      ]"
      @drag="changeFile()"
    >
      <v-card-text style="height: 80px; position: relative;"></v-card-text>
      <v-card-text class="text-center align-center" style="position: relative;"
        ><v-container fluid class="pa-0 ma-0" style="height:100%">
          <v-row align="center">
            <v-col align="center">
              <v-img
                class="text-center"
                lazy-src="/upload.svg"
                src="/upload.svg"
                max-width="450"
                :style="
                  noQuata
                    ? '-webkit-filter: grayscale(100%);filter: grayscale(100%); opacity: 0.8;'
                    : ''
                "
                contain/></v-col></v-row></v-container
        ><v-card-text
          v-if="!noQuata"
          class="text-center display-1"
          style="height: 50px;"
        >
          {{ $t('form.dataGrid.dropFile') }} </v-card-text
        ><v-card-text v-if="!noQuata" class="text-center headline">{{
          $t('form.dataGrid.uploadButtonUse')
        }}</v-card-text>
        <v-card-text
          v-if="noQuata"
          class="text-center display-1"
          style="height: 50px;"
        >
          餘額不足 </v-card-text
        ><v-card-text v-if="noQuata" class="text-center headline"
          >請進行加值</v-card-text
        >
      </v-card-text>
    </v-card>
    <input
      v-if="!noQuata && balance !== null"
      type="file"
      id="upload"
      ref="upload"
      @change="changeFile()"
      :multiple="multiple"
      :accept="fileTypes"
    />
  </v-card>
</template>

<script>
export default {
  props: {
    fileTypes: {
      type: Array,
    },
    multiple: {
      type: Boolean,
    },
    noQuata: {
      type: Boolean,
    },
    balance: {
      type: Number,
    },
  },
  data() {
    return {
      dragging: false,
    };
  },
  created() {},
  mounted() {
    if (this.noQuata) {
      const dragOuter = document.getElementById('dragOuter');

      dragOuter.addEventListener('dragenter', this.preventDefault, false);
      dragOuter.addEventListener('dragleave', this.preventDefault, false);
      dragOuter.addEventListener('dragover', this.preventDefault, false);
      dragOuter.addEventListener('drop', this.preventDefault, false);
    }
  },
  methods: {
    preventDefault(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    changeFile() {
      if (!this.noQuata) {
        this.$emit('changeFile');
      }
    },
    dragenter() {
      if (!this.noQuata) {
        this.dragging = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
.dropZone {
  height: 67vh;
  width: 100%;
  position: center;
  color: #ffffff;
  margin: 50px;
  text-align: center;
}
.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.dropZone-over {
  background: #b5d5f7 !important;
  color: #b5d5f7 !important;
  border: 2px solid #006ad8 !important;
  opacity: 0.8;
}
.dropZone:hover {
  border: 2px solid #006ad8;
}
.dropZone-info {
  width: 100%;
  height: 65%;
  position: absolute;
  top: 33%;
  transform: translate(0, -50%);
  text-align: center;
}
.dropZone-infoover {
  width: 100%;
  height: 65%;
  position: absolute;
  background: #b5d5f7 !important;
  color: #b5d5f7 !important;
  transform: translate(0, -50%);
  text-align: center;
}
.zoneNoQuota {
  background: #f5f5f5 !important;
  height: 67vh;
  width: 100%;
  position: center;
}
.zoneNoQuota:hover {
  border: 0px;
}
.zoneNoQuota-infoover {
  width: 100%;
  height: 65%;
  position: absolute;
  background: #f5f5f5 !important;
  color: #f5f5f5 !important;
  transform: translate(0, -50%);
  text-align: center;
}
</style>
