<template>
  <v-card flat class="mr-3">
    <v-alert text outlined color="error" class="pt-1 pb-1 ma-0">
      <v-icon color="error" class="mr-2" style="margin-top: -5px"
        >mdi-alert</v-icon
      >剩餘點數不足，請移除部分檔案或進行加值
    </v-alert></v-card
  >
</template>

<script>
export default {
  props: {
    balance: {
      type: Number,
    },
  },
};
</script>
