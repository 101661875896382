<template>
  <div>
    <v-card>
      <v-card-title>確認上傳</v-card-title>
      <!-- <v-card-text class="pb-2">目前剩餘額度 {{ balance }} 點， </v-card-text> -->
      <v-card-text
        >上傳後將使用 {{ uploadCount }} 次轉檔額度，確定要上傳嗎?</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="uploadDialogClose()">{{
          $t('form.actions.cancel')
        }}</v-btn>
        <v-btn dark color="primary" @click="uploadConfirm()"
          >{{ $t('form.actions.ok') }}上傳</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    uploadCount: {
      type: Number,
    },
    balance: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  methods: {
    uploadDialogClose() {
      this.$emit('uploadDialogClose');
    },
    uploadConfirm() {
      this.$emit('uploadConfirm');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
</style>
