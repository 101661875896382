<template>
  <td style="text-align: center;">
    <v-progress-circular
      v-if="loadingCheck()"
      indeterminate
      :width="3"
      :size="25"
      color="success"
    ></v-progress-circular>

    <v-icon v-if="successCheck()" :id="'btn_' + dataItem.id" color="success"
      >mdi-check-circle</v-icon
    >

    <v-icon
      v-if="failedCheck()"
      :id="'btn_' + dataItem.id"
      color="error"
      class="mr-2"
      >mdi-alert</v-icon
    >
    <v-tooltip bottom v-if="failedCheck()">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          color="primary"
          fab
          :elevation="0"
          x-small
          class="mr-2"
          @click="retry()"
          ><v-icon>mdi-replay</v-icon></v-btn
        >
      </template>

      <span>重試</span>
    </v-tooltip>
  </td>
</template>

<script>
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  data() {
    return {
      downloadType: "",
    };
  },
  methods: {
    retry() {
      this.$emit("retry", { dataItem: this.dataItem });
    },
    loadingCheck() {
      if (this.dataItem.state == "loading") {
        return true;
      } else {
        return false;
      }
    },
    successCheck() {
      if (this.dataItem.state == "success") {
        return true;
      } else {
        return false;
      }
    },
    failedCheck() {
      if (this.dataItem.state == "failed") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style></style>
